import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const CustomDoughnutChart = ({ data }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
    },
    layout: {
      padding: 20,
    },
  };

  const calculatePercentages = data => {
    const counters = {
      disfluency: 0,
      fluency: 0,
      interjection: 0,
      music: 0,
      naturalPause: 0,
      noSpeech: 0,
      totalFiles: 0,
    };

    data.forEach(item => {
      counters.disfluency += item.disfluency;
      counters.fluency += item.fluency;
      counters.interjection += item.interjection;
      counters.music += item.music;
      counters.naturalPause += item.naturalPause;
      counters.noSpeech += item.noSpeech;
      counters.totalFiles += parseInt(item.totalFiles, 10);
    });

    const percentages = {
      disfluency: (counters.disfluency / counters.totalFiles) * 100,
      fluency: (counters.fluency / counters.totalFiles) * 100,
      interjection: (counters.interjection / counters.totalFiles) * 100,
      music: (counters.music / counters.totalFiles) * 100,
      naturalPause: (counters.naturalPause / counters.totalFiles) * 100,
      noSpeech: (counters.noSpeech / counters.totalFiles) * 100,
    };

    return {
      labels: ['Disfluency', 'Fluency', 'Other'],
      datasets: [
        {
          label: 'Percentage',
          data: [
            percentages.disfluency,
            percentages.fluency,
            percentages.interjection +
              percentages.music +
              percentages.naturalPause +
              percentages.noSpeech,
          ],
          backgroundColor: [
            'rgba(220, 20, 60, 0.2)',
            'rgba(46, 139, 87, 0.2)',
            'rgba(255, 206, 86, 0.2)',
          ],
          borderColor: [
            'rgba(220, 20, 60, 1)',
            'rgba(46, 139, 87, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 2,
        },
      ],
    };
  };

  return <Doughnut data={calculatePercentages(data)} options={options} />;
};

export default CustomDoughnutChart;
