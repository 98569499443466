import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Divider,
  Flex,
  Spacer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Container,
  Badge,
  Button,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  SkeletonText,
} from '@chakra-ui/react';
import { getUserDetails, deleteSession } from '../../services/Admin';
import SessionPrediction from '../../components/Prediction/SessionPrediction';
import { deleteAudioFile } from '../../services/Admin';

const UsersDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const [session, setSession] = useState(null);
  const boxRef = useRef(null);

  const fetchUserDetails = async () => {
    try {
      const res = await getUserDetails(id);
      if (res.status === 200) {
        setDetails(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const customScrollbarStyle = {
    '::-webkit-scrollbar': {
      height: '1px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  };

  const handleScroll = e => {
    const scrollAmount = 200;
    if (e.deltaY > 0) {
      boxRef.current.scrollLeft += scrollAmount;
    } else {
      boxRef.current.scrollLeft -= scrollAmount;
    }
  };

  const handleDeleteAudiofile = async id => {
    // try {
    //   const res = await deleteAudioFile(id);
    //   if (res.status === 200) {
    //     fetchUserDetails();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleDeleteSession = async id => {
    // try {
    //   const res = await deleteSession(id);
    //   if (res.status === 200) {
    //     setSession(null);
    //     fetchUserDetails();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <Box p={4}>
      <Breadcrumb pb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="/admin/users">Users</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">User details</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex>
        <Text fontSize="2xl">
          User Details {details && `| ${details.username}`}
        </Text>
        <Spacer />
      </Flex>

      <Divider orientation="horizontal" bg="#1EE66E" borderWidth="2px" mt={4} />

      {!details && (
        <SkeletonText
          padding={4}
          mt="4"
          noOfLines={4}
          spacing="4"
          skeletonHeight="2"
        />
      )}

      <Box
        ref={boxRef}
        mt={4}
        overflowX="auto"
        sx={customScrollbarStyle}
        display="flex"
        onWheel={handleScroll}
      >
        {details &&
          details.sessions.length !== 0 &&
          [...details.sessions].reverse().map((item, index) => (
            <Box
              key={index}
              border={session?._id === item._id ? '4px solid' : '1px solid'}
              borderColor={session?._id === item._id ? '#1EE66E' : '#165D37'}
              p={4}
              rounded="lg"
              cursor="pointer"
              mr={4}
              onClick={() => setSession(item)}
              minW="200px"
              flexShrink={0}
            >
              <Text fontSize="sm" p={2}>
                Theme: {item?.theme?.theme}
              </Text>
              <Text fontSize="sm" p={2}>
                Created: {item.createdDate}
              </Text>
            </Box>
          ))}
      </Box>

      {session && (
        <Container maxW="4xl">
          <Box mt={4}>
            {session.audioFiles !== 0 && (
              <Accordion defaultIndex={[0]}>
                {session.audioFiles.map((item, index) => (
                  <AccordionItem key={index}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          AudioFile {item._id}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {item.isPredicted && item.prediction && (
                        <VStack>
                          <Badge colorScheme="green" variant="solid">
                            Active
                          </Badge>
                          <SessionPrediction
                            audioFileName={item.filename}
                            audioFileUrl={item.file_url}
                            modelName={item.prediction.modal.type}
                            modelVersion={item.prediction.modal.version}
                            totalFile={Number(item.prediction.totalFiles)}
                            num_files_after_prediction={{
                              Disfluent: item.prediction.disfluency,
                              Fluent: item.prediction.fluency,
                              Interjection: item.prediction.interjection,
                              Music: item.prediction.music,
                              NaturalPause: item.prediction.naturalPause,
                              NoSpeech: item.prediction.noSpeech,
                            }}
                            isPlayer={true}
                            text={item.text}
                          />
                        </VStack>
                      )}
                      {!item.isPredicted && !item.prediction && (
                        <VStack>
                          <Badge colorScheme="red" variant="solid">
                            InActive
                          </Badge>
                          <Button
                            size="sm"
                            colorScheme="red"
                            variant="solid"
                            onClick={() => handleDeleteAudiofile(item._id)}
                          >
                            Delete
                          </Button>
                        </VStack>
                      )}

                      {item.isPredicted && !item.prediction && (
                        <VStack>
                          <Badge colorScheme="red" variant="solid">
                            InActive
                          </Badge>
                          <Button
                            size="sm"
                            colorScheme="red"
                            variant="solid"
                            onClick={() => handleDeleteAudiofile(item._id)}
                          >
                            Delete
                          </Button>
                        </VStack>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            )}

            {session.audioFiles.length === 0 && (
              <VStack p={6}>
                <Text fontSize="lg">Session Empty.</Text>
                <Button
                  colorScheme="red"
                  onClick={() => handleDeleteSession(session._id)}
                >
                  Delete Session
                </Button>
              </VStack>
            )}
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default UsersDetails;
