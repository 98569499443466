import { Box, Text, Center, Wrap, WrapItem, Container } from '@chakra-ui/react';

import { getActivitySessionList } from '../../services/Common';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import spinner from '../../components/Loader/spinner.json';

const Sessions = () => {
  const navigate = useNavigate();
  const [isLoading, setUpLoading] = useState(false);
  const [sessionsList, setSessionList] = useState([]);

  const fetchSessionList = async () => {
    setUpLoading(true);
    try {
      const res = await getActivitySessionList();

      if (res.status === 200) {
        setSessionList(res.data.activities);
        setUpLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSessionList();
  }, []);

  return (
    <Box>
      <Center>
        <Box textAlign="center">
          <Text fontSize="2xl" as="b">
            Sessions
          </Text>
        </Box>
      </Center>

      {isLoading && (
        <Box
          position="fixed"
          top="50%"
          left="55%"
          transform="translate(-50%, -50%)"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Container maxW="6xl">
            <Center>
              <Box>
                <Lottie animationData={spinner} loop={true} />
              </Box>
            </Center>
          </Container>
        </Box>
      )}

      {!isLoading && (
        <Center>
          <Wrap spacing={2} p={4}>
            {sessionsList.length !== 0 &&
              [...sessionsList].reverse().map((item, index) => (
                <WrapItem>
                  <Box
                    key={index}
                    bg="#232340"
                    p={4}
                    rounded="lg"
                    cursor="pointer"
                    mr={4}
                    onClick={() => navigate(`/user/sessions/${item._id}`)}
                    minW="200px"
                    flexShrink={0}
                  >
                    <Text fontSize="sm" p={2}>
                      Theme: {item?.theme?.theme}
                    </Text>
                    <Text fontSize="sm" p={2}>
                      Created: {item.createdDate}
                    </Text>
                  </Box>
                </WrapItem>
              ))}
          </Wrap>
        </Center>
      )}
    </Box>
  );
};

export default Sessions;
