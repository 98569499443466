import React, { useState, useEffect } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Modal from './view/Admin/Modal';
import Login from './view/Login';
import Admin from './view/Admin/Admin';
import AdminSideBar from './components/Layout/Admin/SideBar';
import UserSideBar from './components/Layout/User/SideBar';
import ModalDetails from './view/Admin/ModalDetails';
import Home from './view/User/Home';
import Activity from './view/User/Activity';
import ActivitySessionResults from './view/User/ActivitySessionResults';
import Activities from './view/Admin/Activities';
import ActivityDetails from './view/Admin/ActivityDetails';
import Users from './view/Admin/Users';
import UsersDetails from './view/Admin/UsersDetails';
import Classification from './view/Admin/Classification';

import Conversation from './view/User/Conversation';
import Sessions from './view/User/Sessions';

import './Global.css';

const theme = extendTheme({
  styles: {
    global: props => ({
      body: {
        bg: props.colorMode === 'dark' ? '#1e1e2d ' : '#1e1e2d ',
      },
    }),
  },
});

const PrivateRoute = ({ element, adminOnly }) => {
  const token = Cookies.get('token');
  const isAdminCookie = Cookies.get('isAdmin');
  const isAdmin = isAdminCookie === 'true';
  const isAuth = !!token;

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && !isAdmin) {
    return <Navigate to="/home" />;
  }

  if (!adminOnly && isAdmin) {
    return <Navigate to="/" />;
  }

  return element;
};

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    localStorage.setItem('chakra-ui-color-mode', 'dark');
  }, []);

  useEffect(() => {
    const isAdminCookie = Cookies.get('isAdmin');
    setIsAdmin(isAdminCookie === 'true');

    const token = Cookies.get('token');
    setIsAuth(!!token);

    const handleCookieChange = () => {
      const updatedIsAdminCookie = Cookies.get('isAdmin');
      setIsAdmin(updatedIsAdminCookie === 'true');

      const updatedToken = Cookies.get('token');
      setIsAuth(!!updatedToken);
    };

    window.addEventListener('storage', handleCookieChange);

    return () => {
      window.removeEventListener('storage', handleCookieChange);
    };
  }, [isAuth]);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute
                element={
                  <AdminSideBar>
                    <Admin />
                  </AdminSideBar>
                }
                path="/"
                adminOnly={true}
              />
            }
          />
          <Route
            path="/admin/users"
            element={
              <PrivateRoute
                element={
                  <AdminSideBar>
                    <Users />
                  </AdminSideBar>
                }
                path="/admin/users"
                adminOnly={true}
              />
            }
          />

          <Route
            path="/admin/users/:id"
            element={
              <PrivateRoute
                element={
                  <AdminSideBar>
                    <UsersDetails />
                  </AdminSideBar>
                }
                path="/admin/users"
                adminOnly={true}
              />
            }
          />

          <Route
            path="/admin/modals"
            element={
              <PrivateRoute
                element={
                  <AdminSideBar>
                    <Modal />
                  </AdminSideBar>
                }
                path="/admin/modals"
                adminOnly={true}
              />
            }
          />
          <Route
            path="/admin/modals/:id"
            element={
              <PrivateRoute
                element={
                  <AdminSideBar>
                    <ModalDetails />
                  </AdminSideBar>
                }
                path="/admin/modals/:id"
                adminOnly={true}
              />
            }
          />
          <Route
            path="/admin/activities"
            element={
              <PrivateRoute
                element={
                  <AdminSideBar>
                    <Activities />
                  </AdminSideBar>
                }
                path="/admin/activities"
                adminOnly={true}
              />
            }
          />
          <Route
            path="/admin/activities/:id"
            element={
              <PrivateRoute
                element={
                  <AdminSideBar>
                    <ActivityDetails />
                  </AdminSideBar>
                }
                path="/admin/activities/:id"
                adminOnly={true}
              />
            }
          />

          <Route
            path="/admin/classifications"
            element={
              <PrivateRoute
                element={
                  <AdminSideBar>
                    <Classification />
                  </AdminSideBar>
                }
                path="/admin/classifications"
                adminOnly={true}
              />
            }
          />

          <Route
            path="/home"
            element={
              <PrivateRoute
                element={
                  <UserSideBar>
                    <Home />
                  </UserSideBar>
                }
                path="/home"
                adminOnly={false}
              />
            }
          />
          <Route
            path="/user/activity"
            element={
              <PrivateRoute
                element={
                  <UserSideBar>
                    <Activity />
                  </UserSideBar>
                }
                path="/user/activity"
                adminOnly={false}
              />
            }
          />

          <Route
            path="/user/activity"
            element={
              <PrivateRoute
                element={
                  <UserSideBar>
                    <Activity />
                  </UserSideBar>
                }
                path="/user/activity"
                adminOnly={false}
              />
            }
          />
          <Route
            path={`/user/activity/simple-dialog/:id`}
            element={
              <PrivateRoute
                element={
                  <UserSideBar>
                    <Conversation />
                  </UserSideBar>
                }
                path={`/user/activity/simple-dialog/:id`}
                adminOnly={false}
              />
            }
          />
          <Route
            path="/user/sessions"
            element={
              <PrivateRoute
                element={
                  <UserSideBar>
                    <Sessions />
                  </UserSideBar>
                }
                path="/user/sessions"
                adminOnly={false}
              />
            }
          />
          <Route
            path={`/user/sessions/:sessionId`}
            element={
              <PrivateRoute
                element={
                  <UserSideBar>
                    <ActivitySessionResults />
                  </UserSideBar>
                }
                path={`/user/sessions/:sessionId`}
                adminOnly={false}
              />
            }
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
