import {
  Box,
  Stat,
  Center,
  WrapItem,
  Wrap,
  Text,
  Divider,
  Flex,
  StatLabel,
  StatNumber,
  Spacer,
  useDisclosure,
  Skeleton,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Container,
} from '@chakra-ui/react';
import { FiActivity } from 'react-icons/fi';
import BackgroundNoiseChecker from '../../components/BackgroundNoiseChecker';
import { getActivityList } from '../../services/Common';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ThemeMenu from '../../components/Activity/ThemeMenu';
import Lottie from 'lottie-react';
import spinner from '../../components/Loader/spinner.json';

const customScrollbarStyle = {
  '::-webkit-scrollbar': {
    height: '1px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(0,0,0,0)',
  },
};

const Activity = () => {
  const [activity, setActivity] = useState([]);
  const [selectedStat, setSelectedStat] = useState(null);
  const [activitySessions, setActivitySessionList] = useState([]);
  const [isBackgroundNoiseHigh, setIsBackgroundNoiseHigh] = useState(false);
  const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const navigate = useNavigate();
  const boxRef = useRef(null);

  const fetchActivityList = async () => {
    try {
      const res = await getActivityList();

      if (res.status === 200) {
        setActivity(res.data.activities);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSessionResult = async sessionId => {
    navigate(
      `/user/activity/simple-dialog/666a948d4d5cfd71e72ef22f/${sessionId}`
    );
  };

  const handleStatClick = index => {
    if (isThemeMenuOpen) {
      setIsThemeMenuOpen(false);
    } else {
      onToggle();
      setSelectedStat(selectedStat === index ? null : index);
    }
  };

  const handleScroll = e => {
    const scrollAmount = 200;
    if (e.deltaY > 0) {
      boxRef.current.scrollLeft += scrollAmount;
    } else {
      boxRef.current.scrollLeft -= scrollAmount;
    }
  };

  useEffect(() => {
    fetchActivityList();
  }, []);

  useEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => {
      if (isOpen && isBackgroundNoiseHigh) {
        onToggle();
        setIsThemeMenuOpen(false);
      } else if (isOpen && !isBackgroundNoiseHigh) {
        setIsThemeMenuOpen(true);
        onToggle();
      }
    }, 5000);

    return () => {
      clearTimeout(timeoutId); // Cleanup the timeout if component unmounts or dependencies change
    };
  }, [isOpen, isBackgroundNoiseHigh, onToggle, setIsThemeMenuOpen]);

  return (
    <Box>
      <Center>
        <Box textAlign="center">
          <Text fontSize="2xl" as="b">
            Activities
          </Text>
        </Box>
      </Center>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size="lg"
      >
        <ModalOverlay />
        <ModalContent border="none">
          <ModalBody bg="#232340" rounded="md">
            <BackgroundNoiseChecker
              backgroundNoise={setIsBackgroundNoiseHigh}
              text="Checking audio quality..."
              isAnimation={true}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {activity.length === 0 && (
        <Box
          position="fixed"
          top="50%"
          left="55%"
          transform="translate(-50%, -50%)"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Container maxW="6xl">
            <Center>
              <Box>
                <Lottie animationData={spinner} loop={true} />
              </Box>
            </Center>
          </Container>
        </Box>
      )}

      {activity.length !== 0 && (
        <Wrap spacing="30px" m={4}>
          {activity.map((item, index) => (
            <WrapItem key={index}>
              <Box width="100%">
                <Stat
                  px={{ base: 2, md: 4 }}
                  py={'5'}
                  shadow={'xl'}
                  bg="#232340"
                  rounded={'lg'}
                  cursor="pointer"
                  onClick={() => handleStatClick(index)}
                >
                  <Flex justifyContent={'space-between'}>
                    <Box pl={{ base: 2, md: 4 }}>
                      <StatLabel fontWeight={'medium'} isTruncated>
                        {item.title}
                      </StatLabel>
                      <StatNumber
                        fontSize={'xl'}
                        fontWeight={'medium'}
                      ></StatNumber>
                    </Box>
                    <Box ml={8} my={'auto'} alignContent={'center'}>
                      <FiActivity size={'2em'} />
                    </Box>
                  </Flex>
                </Stat>

                {item && item.theme && (
                  <ThemeMenu item={item} open={isThemeMenuOpen} />
                )}
              </Box>
            </WrapItem>
          ))}
        </Wrap>
      )}

      {activitySessions.length !== 0 && (
        <>
          <Flex>
            <Text fontSize="2xl">Sessions</Text>
            <Spacer />
          </Flex>

          <Divider
            orientation="horizontal"
            bg="#1EE66E"
            borderWidth="2px"
            mt={4}
          />

          <Box
            ref={boxRef}
            mt={4}
            overflowX="auto"
            sx={customScrollbarStyle}
            display="flex"
            onWheel={handleScroll}
          >
            {activitySessions.length === 0 && (
              <Stack padding={4} spacing={1} direction={['column', 'row']}>
                <Skeleton
                  m={2}
                  height="80px"
                  width="200px"
                  isLoaded={activity.length !== 0}
                />
                <Skeleton
                  m={2}
                  height="80px"
                  width="200px"
                  isLoaded={activity.length !== 0}
                />
                <Skeleton
                  m={2}
                  height="80px"
                  width="200px"
                  isLoaded={activity.length !== 0}
                />
              </Stack>
            )}

            {activitySessions.length !== 0 &&
              [...activitySessions].reverse().map((item, index) => (
                <Box
                  key={index}
                  border="1px solid"
                  borderColor="#165D37"
                  p={4}
                  rounded="sm"
                  cursor="pointer"
                  mr={4}
                  onClick={() => handleSessionResult(item._id)}
                  minW="200px"
                  flexShrink={0}
                >
                  <Text fontSize="sm" p={2}>
                    Theme: {item?.theme?.theme}
                  </Text>
                  <Text fontSize="sm" p={2}>
                    Created: {item.createdDate}
                  </Text>
                </Box>
              ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Activity;
