import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Flex, Center, IconButton, Spacer } from '@chakra-ui/react';
import {
  FaPlay,
  FaStepBackward,
  FaStepForward,
  FaUser,
  FaUndo,
  FaStop,
} from 'react-icons/fa';
import { MdRecordVoiceOver } from 'react-icons/md';
import AnimatedBox from './AnimatedBox';
import AudioPlayer from '../AudioPlayer';

const UserResponse = ({
  setCurrentIndex,
  currentIndex,
  currentDialogue,
  handleNextDialogue,
  handlePrevDialogue,
  length,
  saveAudio,
}) => {
  const [animationDirection, setAnimationDirection] = useState('up');
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);

  const [audioChunks, setAudioChunks] = useState([]);

  const [transcript, setTranscript] = useState('');
  const [isRecording, setIsRecording] = useState(false);

  const [audioURL, setAudioURL] = useState('');
  const [recognition, setRecognition] = useState(null);
  const isRecordingRef = useRef(isRecording);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const transcriptRef = useRef('');

  const nextDialogue = () => {
    // Stop recognition if it's active
    if (recognition) {
      recognition.stop();
    }
    setIsRecording(false);

    // Stop recording if it's active
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
    }

    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
    }

    setMediaRecorder(null);
    setMediaStream(null);
    setTranscript('');
    handleStopRecording();
    setAnimationDirection('up');
    handleNextDialogue();
  };

  const prevDialogue = () => {
    // Stop recognition if it's active
    if (recognition) {
      recognition.stop();
    }
    setIsRecording(false);

    // Stop recording if it's active
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
    }

    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
    }

    setMediaRecorder(null);
    setMediaStream(null);
    setTranscript('');
    handleStopRecording();
    setAnimationDirection('down');
    handlePrevDialogue();
  };

  const startRecognition = async () => {
    if (!recognition) {
      console.error('MediaRecorder is not initialized.');
      return;
    }

    recognition.lang = 'en-US';
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;

    if (!mediaRecorder) {
      console.error('MediaRecorder is not initialized.');
      return;
    }

    if (mediaRecorder.state === 'inactive') {
      mediaRecorder.start();
    }

    mediaRecorder.addEventListener('dataavailable', e => {
      if (e.data.size > 0) {
        setAudioChunks(chunks => [...chunks, e.data]);
      }
    });

    recognition.onresult = event => {
      let interimTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptPart = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          setTranscript(prevTranscript => {
            const updatedTranscript = prevTranscript + transcriptPart;
            transcriptRef.current = updatedTranscript;
            return updatedTranscript;
          });
        } else {
          interimTranscript += transcriptPart;
        }
      }
    };

    recognition.onerror = event => {
      console.error('Recognition error', event);
      //   recognition.stop();
    };

    recognition.onend = () => {
      //   console.log('isRecording:', isRecordingRef.current);

      if (isRecordingRef.current) {
        recognition.start();
      } else {
        recognition.stop();
        if (mediaRecorder.state === 'recording') {
          mediaRecorder.stop();
        }
      }
    };

    recognition.start();
  };

  const handleStartRecording = () => {
    const newRecognition = new window.webkitSpeechRecognition();
    setRecognition(newRecognition);
    setIsRecording(true);
    isRecordingRef.current = true;
  };

  const handleStopRecording = () => {
    if (recognition) {
      recognition.stop();
    }
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
    }
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
    }
    setIsRecording(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    isRecordingRef.current = false;
  };

  const handleReset = () => {
    setTranscript('');
  };

  useEffect(() => {
    if (!mediaRecorder && isRecording) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          mediaRecorderRef.current = new MediaRecorder(stream);
          mediaRecorderRef.current.ondataavailable = event => {
            audioChunksRef.current.push(event.data);
          };

          mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, {
              type: 'audio/webm',
            });

            saveAudio(prevAudioList => [
              ...prevAudioList,
              {
                blob: audioBlob,
                url: audioURL,
                transcript: transcriptRef.current,
                dialogue: currentDialogue.text,
              },
            ]);
            const audioURL = URL.createObjectURL(audioBlob);
            setAudioURL(audioURL);

            audioChunksRef.current = [];
          };

          mediaRecorderRef.current.start();

          setMediaRecorder(mediaRecorderRef.current);
          setMediaStream(stream);
        })
        .catch(err => {
          console.error('Error accessing microphone:', err);
        });
    }
  }, [mediaRecorder, isRecording]);

  useEffect(() => {
    if (isRecording && mediaRecorder) {
      startRecognition();
    }

    if (!isRecording) {
    }
  }, [isRecording, mediaRecorder]);

  return (
    <Box overflow="hidden">
      <AnimatedBox direction={animationDirection}>
        {currentDialogue && (
          <Center>
            <Flex m={6}>
              {!isRecording && (
                <IconButton
                  m={4}
                  p={2}
                  variant="ghost"
                  icon={<FaPlay color="#1EE66E" fontSize="24px" />}
                  onClick={handleStartRecording}
                />
              )}

              {!isRecording && transcript && (
                <IconButton
                  m={4}
                  p={2}
                  variant="ghost"
                  icon={<FaUndo color="#f56565" fontSize="24px" />}
                  onClick={handleReset}
                />
              )}

              {isRecording && (
                <IconButton
                  m={4}
                  p={2}
                  variant="ghost"
                  icon={<FaStop color="#f56565" fontSize="24px" />}
                  onClick={handleStopRecording}
                />
              )}
            </Flex>
          </Center>
        )}
        <Flex>
          <Box p={8} bg="#232340" borderRadius={10}>
            <Flex>
              <Box>
                {isRecording && (
                  <IconButton
                    variant="ghost"
                    icon={<MdRecordVoiceOver color="#f56565" fontSize="24px" />}
                  />
                )}
                <Text fontSize="xl" pl={4} pr={4}>
                  {currentDialogue.text}
                </Text>
                {transcript && (
                  <Box mt={4} p={4} bg="#161622" borderRadius={10}>
                    <Text fontSize="sm" pl={4} pr={4} as="i">
                      {transcript}
                    </Text>
                    {audioURL && (
                      <Box>
                        <AudioPlayer audioUrl={audioURL} />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Flex>
          </Box>

          <IconButton
            ml={4}
            isRound
            variant="solid"
            bg="#1EE66E"
            icon={<FaUser color="black" fontSize="22px" />}
            _hover={{ bg: '#1FB159' }}
          />
        </Flex>
        <Center>
          <Flex m={6}>
            <IconButton
              isDisabled={currentIndex === 0}
              m={4}
              isRound
              variant="solid"
              bg="#1EE66E"
              icon={<FaStepBackward color="black" />}
              _hover={{ bg: '#1FB159' }}
              onClick={prevDialogue}
            />
            <IconButton
              isDisabled={currentIndex >= length - 1}
              m={4}
              isRound
              variant="solid"
              bg="#1EE66E"
              icon={<FaStepForward color="black" />}
              _hover={{ bg: '#1FB159' }}
              onClick={nextDialogue}
            />
          </Flex>
        </Center>
      </AnimatedBox>
    </Box>
  );
};

export default UserResponse;
