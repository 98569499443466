import { Box, Center, Wrap, WrapItem, Container } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import StatsCard from '../../components/StatsCard';
import { FiServer, FiActivity } from 'react-icons/fi';
import { getActivitySessionList } from '../../services/Common';
import Lottie from 'lottie-react';
import spinner from '../../components/Loader/spinner.json';

const Home = () => {
  const navigate = useNavigate();

  const [sessionsList, setSessionList] = useState([]);
  const [isLoading, setUpLoading] = useState(false);

  const fetchSessionList = async () => {
    setUpLoading(true);
    try {
      const res = await getActivitySessionList();

      if (res.status === 200) {
        setUpLoading(false);
        setSessionList(res.data.activities);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSessionList();
  }, []);

  return (
    <Box p={4}>
      {isLoading && (
        <Box
          position="fixed"
          top="50%"
          left="55%"
          transform="translate(-50%, -50%)"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Container maxW="6xl">
            <Center>
              <Box>
                <Lottie animationData={spinner} loop={true} />
              </Box>
            </Center>
          </Container>
        </Box>
      )}

      {!isLoading && (
        <Wrap spacing="30px">
          <WrapItem>
            <StatsCard
              title={'Activity'}
              stat={1}
              icon={<FiActivity size={'3em'} />}
              click={() => navigate('/user/activity')}
            />
          </WrapItem>
          {sessionsList.length !== 0 && (
            <WrapItem>
              <StatsCard
                title={'Session'}
                stat={sessionsList?.length}
                icon={<FiServer size={'3em'} />}
                click={() => navigate('/user/sessions')}
              />
            </WrapItem>
          )}
        </Wrap>
      )}
    </Box>
  );
};

export default Home;
