import axios from 'axios';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  const token = Cookies.get('token');
  if (!token) throw new Error('Token not found');
  return token;
};

const handleError = error => {
  console.error(error);
  throw new Error('An error occurred while processing the request');
};

const getHeaders = (contentType = 'application/json') => ({
  'Content-Type': contentType,
  Authorization: `Bearer ${getToken()}`,
});

export const uploadModalFile = async (file, modalType) => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('modalType', String(modalType));

    const response = await axios.post(
      `${apiUrl}/common/upload-modal-file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`, // Include token as Bearer token in the header
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const listUsers = async () => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.get(`${apiUrl}/admin/list-users`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include token as Bearer token in the header
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getUserDetails = async id => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.get(`${apiUrl}/admin/user-details/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include token as Bearer token in the header
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getModal = async id => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.get(`${apiUrl}/admin/modal/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include token as Bearer token in the header
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getModals = async () => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.get(`${apiUrl}/admin/list-modal`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include token as Bearer token in the header
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const addModal = async (type, accuracy, version, isActive) => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.post(
      `${apiUrl}/admin/modal`,
      {
        type: type,
        accuracy: accuracy,
        version: version,
        isActive: isActive,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const updateStatus = async (id, isActive) => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.put(
      `${apiUrl}/admin/modal/${id}`,
      {
        isActive: isActive,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const deleteModal = async id => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.delete(`${apiUrl}/admin/modal/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const getAllAudioFiles = async () => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.get(`${apiUrl}/admin/audio-files`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include token as Bearer token in the header
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAllPrediction = async () => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.get(`${apiUrl}/admin/prediction`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include token as Bearer token in the header
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getCount = async () => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.get(`${apiUrl}/admin/count`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include token as Bearer token in the header
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const addActivity = async (title, endpoint) => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.post(
      `${apiUrl}/admin/activity`,
      {
        title: title,
        endpoint: endpoint,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const deleteActivity = async id => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.delete(`${apiUrl}/admin/activity/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const listActivityTheme = async activity_id => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.get(
      `${apiUrl}/admin/activity-theme/${activity_id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const addActivityTheme = async (theme, activity_id) => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.post(
      `${apiUrl}/admin/activity-theme`,
      {
        theme: theme,
        activity_id: activity_id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const deleteActivityTheme = async id => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.delete(
      `${apiUrl}/admin/activity-theme/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const deleteSession = async id => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.delete(
      `${apiUrl}/admin/activity-session/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const getDialogue = async id => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.get(`${apiUrl}/admin/dialogue/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response; // Return the response data
  } catch (error) {
    console.error(error);
    throw new Error('Error logging in');
  }
};

export const deleteAudioFile = async fileId => {
  try {
    const token = Cookies.get('token'); // Get token from cookie

    if (!token) {
      throw new Error('Token not found');
    }

    const response = await axios.delete(
      `${apiUrl}/admin/audio-files/${fileId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include token as Bearer token in the header
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteClassification = async id => {
  try {
    const response = await axios.delete(
      `${apiUrl}/admin/classification/${id}`,
      {
        headers: getHeaders(),
      }
    );
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const getClassifications = async () => {
  try {
    const response = await axios.get(`${apiUrl}/admin/list-classifications`, {
      headers: getHeaders(),
    });
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

// Add Classification
export const addClassification = async title => {
  try {
    const response = await axios.post(
      `${apiUrl}/admin/classification`,
      { title },
      { headers: getHeaders() }
    );
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
