import React from 'react';
import { Box, Text, Center, Container, Button } from '@chakra-ui/react';
import { userDetails, getDialogue } from '../../services/Common';
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ConversationCard from '../../components/Conversation/ConversationCard';
import Lottie from 'lottie-react';
import animationData from '../../components/Loader/loader.json';
import uploader from '../../components/Loader/uploader.json';
import { saveAudioData, addActivitySession } from '../../services/Common';
import uploadAudioFile from '../../Utils/uploadAudio';

const Conversation = () => {
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isUpLoading, setIsUpLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);
  const [dialogue, setDialogue] = useState([]);
  const [currentDialogue, setCurrentDialogue] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isUser, setIsUser] = useState(false);
  const [audioList, setAudioList] = useState([]);

  const [prevDialogue, setPrevDialogue] = useState(null);

  const fetchUser = async () => {
    try {
      const res = await userDetails();

      if (res.status === 200) {
        setUser(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const convertApiResponseToDialogue = dialogue => {
    const dialogues = dialogue
      .split('\n\n')
      .filter(line => line.includes(':'))
      .map(line => {
        const [speaker, text] = line.split(':', 2);
        return {
          speaker: speaker.replace(/\*/g, '').trim(),
          text: text.replace(/\*/g, '').trim(),
        };
      });

    return dialogues;
  };

  const fetchDialogue = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const optionValue = searchParams.get('option');

      const res = await getDialogue(optionValue);
      if (res.status === 200) {
        setData(res.data);
        const convertedDialogue = await convertApiResponseToDialogue(
          res.data.feedback
        );

        setDialogue(convertedDialogue);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextDialogue = () => {
    setIsUser(data?.friend_name === currentDialogue.speaker);
    setCurrentIndex(prevIndex => {
      const newIndex = prevIndex + 1;
      setPrevDialogue(dialogue[prevIndex]);
      setCurrentDialogue(dialogue[newIndex]);
      return newIndex;
    });
  };

  const handlePrevDialogue = () => {
    setIsUser(data?.friend_name === currentDialogue.speaker);
    setCurrentIndex(prevIndex => {
      const newIndex = prevIndex - 1;
      setPrevDialogue(dialogue[newIndex]);
      setCurrentDialogue(dialogue[prevIndex]);
      return newIndex;
    });
  };

  function generateUniqueFilename(prefix = 'audio') {
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    const randomString = Math.random().toString(36).substring(2, 8);
    return `${prefix}_${timestamp}_${randomString}.webm`;
  }

  const handleSubmitData = async () => {
    setIsUpLoading(true);
    if (audioList.length === 0) return;

    const searchParams = new URLSearchParams(location.search);
    const themeId = searchParams.get('option');

    let sessionId = null;

    try {
      const res = await addActivitySession(id, themeId);

      if (res.status === 201) {
        sessionId = res.data._id;

        for (let i = 0; i < audioList.length; i++) {
          const audio = audioList[i];

          try {
            const s3Url = await uploadAudioFile(
              audio.blob,
              generateUniqueFilename()
            );
            // console.log(`File uploaded successfully: ${s3Url}`);

            const res = await saveAudioData(
              s3Url,
              audio.dialogue,
              audio.transcript,
              id,
              themeId,
              sessionId
            );

            // if (res.status === 200) {
            //   navigate(`/user/activity/sessions/${res.data.session_id}`);
            // }
          } catch (error) {
            console.error(
              `Error during file upload or URL save: ${error.message}`
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUpLoading(false);
    }

    if (sessionId) {
      navigate(`/user/sessions/${sessionId}`);
    }
  };

  useEffect(() => {
    fetchDialogue();
  }, []);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (data && dialogue.length !== 0) {
      setCurrentDialogue(dialogue[currentIndex]);
    }
  }, [dialogue, currentIndex]);

  return (
    <Box p={4} position="relative">
      <Center>
        <Box textAlign="center">
          <Text fontSize="2xl" as="b">
            Conversation
          </Text>
        </Box>
      </Center>

      <Center mt={10}>
        <Box textAlign="center">
          <Text as="sub" color="#B8C5D0">
            Welcome to the AI Bot Reading Activity.
            <br />
            You can stop, play, or pause the activity at any time.
            <br />
            Each section will be revealed sequentially, and simply click "next"
            to proceed.
          </Text>
        </Box>
      </Center>

      {isUpLoading && (
        <Container maxW="6xl" mt={10}>
          <Center>
            <Box w="25%">
              <Lottie animationData={uploader} loop={true} />
            </Box>
          </Center>
        </Container>
      )}

      {!currentDialogue && (
        <Container maxW="6xl" mt={10}>
          <Center>
            <Lottie animationData={animationData} loop={true} />
          </Center>
        </Container>
      )}

      {data && dialogue && user && currentDialogue && !isUpLoading && (
        <Container maxW="6xl" mt={10}>
          <Box p={4}>
            <Center>
              <ConversationCard
                setCurrentIndex={setCurrentIndex}
                currentIndex={currentIndex}
                currentDialogue={currentDialogue}
                handleNextDialogue={handleNextDialogue}
                handlePrevDialogue={handlePrevDialogue}
                length={dialogue.length}
                isUser={isUser}
                saveAudio={setAudioList}
              />
            </Center>
          </Box>
          {audioList.length !== 0 && currentIndex >= dialogue.length - 1 && (
            <Center>
              <Button
                variant="outline"
                borderColor="#1EE66E"
                color="#1EE66E"
                size="sm"
                onClick={handleSubmitData}
              >
                Submit
              </Button>
            </Center>
          )}
        </Container>
      )}
    </Box>
  );
};

export default Conversation;
