import {
  Box,
  Text,
  Flex,
  Spacer,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  ListItem,
  UnorderedList,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  SkeletonText,
  SimpleGrid,
  Fade,
  List,
  ListIcon,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  FaPlus,
  FaTrash,
  FaArrowRight,
  FaUser,
  FaLaptop,
} from 'react-icons/fa';
import {
  listActivityTheme,
  addActivityTheme,
  deleteActivityTheme,
  getDialogue,
} from '../../services/Admin';

const ActivityDetails = () => {
  let { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState(null);
  const [activityThemeList, setActivityThemeList] = useState([]);
  const [dialogue, setDialogue] = useState([]);
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const fetchActivityThemeList = async () => {
    try {
      const res = await listActivityTheme(id);

      if (res.status === 200) {
        setActivityThemeList(res.data.theme);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddActivityTheme = async () => {
    try {
      const res = await addActivityTheme(theme, id);
      if (res.status === 201) {
        setLoading(false);
        fetchActivityThemeList();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteActivityTheme = async id => {
    // try {
    //   const res = await deleteActivityTheme(id);
    //   if (res.status === 200) {
    //     console.log(res);
    //     setLoading(false);
    //     fetchActivityThemeList();
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleOpenTheme = async id => {
    try {
      const res = await getDialogue(id);

      if (res.status === 200) {
        setDialogue(res.data.dialogue);
      } else {
        console.log(res);
        setDialogue([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const convertApiResponseToDialogue = dialogue => {
    const dialogues = dialogue
      .split('\n\n')
      .filter(line => line.includes(':'))
      .map(line => {
        const [speaker, text] = line.split(':', 2);
        return {
          speaker: speaker.replace(/\*\*/g, '').trim(),
          text: text.trim(),
        };
      });

    return dialogues;
  };

  const capitalizeFirstLetter = str => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  useEffect(() => {
    fetchActivityThemeList();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Activity Theme</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Theme</FormLabel>
              <Input value={theme} onChange={e => setTheme(e.target.value)} />
              <FormHelperText>Enter the Activity theme.</FormHelperText>
            </FormControl>

            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </ModalBody>

          <ModalFooter>
            <Button
              // isLoading={isSubmit}
              mt={10}
              mr={2}
              bg="#1EE66E"
              color={'black'}
              rounded={'xl'}
              boxShadow={'xl'}
              _hover={{
                bg: '#1EE66E.500',
              }}
              _focus={{
                bg: '#1EE66E.500',
              }}
              onClick={handleAddActivityTheme}
            >
              Add Activity Theme
            </Button>

            <Button
              // isLoading={isSubmit}
              mt={10}
              ml={2}
              bg="#FB493F"
              color={'white'}
              rounded={'xl'}
              boxShadow={'xl'}
              _hover={{
                bg: '#FB493F.500',
              }}
              _focus={{
                bg: '#FB493F.500',
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box p={4}>
        <Breadcrumb pb={4}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="/admin/activities">Activities</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Activity details</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        {activityThemeList.length === 0 && (
          <SkeletonText
            padding={4}
            mt="4"
            noOfLines={4}
            spacing="4"
            skeletonHeight="2"
          />
        )}

        {activityThemeList.length !== 0 && (
          <>
            <Flex>
              <Text fontSize="2xl" pb={4}>
                Activity Theme
              </Text>
              <Spacer />
              <IconButton
                variant="outline"
                borderColor="#1EE66E"
                color="black"
                size="sm"
                icon={<FaPlus size="12px" color="#1EE66E" />}
                onClick={onOpen}
              />
            </Flex>
            <UnorderedList styleType="' '">
              {activityThemeList.map((item, index) => (
                <ListItem key={index}>
                  <Flex
                    display="flex"
                    p={2}
                    color="white"
                    mt={2}
                    bg="#232340"
                    border={
                      item._id === dialogue[0]?.activityTheme_id
                        ? '1px solid '
                        : 'none'
                    }
                    borderColor={
                      item._id === dialogue[0]?.activityTheme_id
                        ? '#1EE66E'
                        : 'none'
                    }
                    rounded="sm"
                    shadow="md"
                    // cursor="pointer"
                  >
                    <Text pr={2}>{item.theme}</Text>

                    <Spacer />
                    <IconButton
                      mr={2}
                      size="xs"
                      variant="outline"
                      borderColor="#FB493F"
                      icon={<FaTrash size="10px" color="#FB493F" />}
                      onClick={() => handleDeleteActivityTheme(item._id)}
                    />
                    <IconButton
                      size="xs"
                      variant="outline"
                      borderColor="#1EE66E"
                      icon={<FaArrowRight size="10px" color="#1EE66E" />}
                      onClick={() => handleOpenTheme(item._id)}
                    />
                  </Flex>
                </ListItem>
              ))}
            </UnorderedList>
          </>
        )}
      </Box>

      {dialogue.length !== 0 && (
        <Box p={4}>
          <Flex>
            <Text fontSize="2xl" pb={4}>
              Dialogue based on Theme
            </Text>
            <Spacer />
          </Flex>
          <Wrap>
            {dialogue.map((item, index) => (
              <WrapItem key={index}>
                <Box
                  mt={2}
                  border={'1px solid '}
                  borderColor={'#1EE66E'}
                  p={4}
                  maxW={'40vw'}
                  w={'full'}
                  rounded="lg"
                  position="relative"
                >
                  <List
                    display="flex"
                    flexDirection="column"
                    maxH={200}
                    overflow="auto"
                  >
                    {convertApiResponseToDialogue(item.feedback).map(
                      (line, index) => (
                        <ListItem
                          key={index}
                          maxWidth="60%"
                          alignSelf={
                            line.speaker ===
                            capitalizeFirstLetter(item.username)
                              ? 'flex-end'
                              : 'flex-start'
                          }
                          mb={2}
                        >
                          <Flex
                            flexDirection={
                              line.speaker ===
                              capitalizeFirstLetter(item.username)
                                ? 'row-reverse'
                                : 'row'
                            }
                            alignItems="center"
                          >
                            <ListIcon
                              boxSize="1em"
                              as={
                                line.speaker ===
                                capitalizeFirstLetter(item.username)
                                  ? FaUser
                                  : FaLaptop
                              }
                              color="#1EE66E"
                              ml={
                                line.speaker ===
                                capitalizeFirstLetter(item.username)
                                  ? 1
                                  : 0
                              }
                              mr={
                                line.speaker ===
                                capitalizeFirstLetter(item.username)
                                  ? 0
                                  : 1
                              }
                            />
                            <Box p={2} bg="#232340" rounded="sm">
                              <Flex
                                flexDirection={
                                  line.speaker ===
                                  capitalizeFirstLetter(item.username)
                                    ? 'row'
                                    : 'row-reverse'
                                }
                                alignItems="center"
                                spacing={1}
                              >
                                <Text fontSize="xs">{line.text}</Text>
                              </Flex>
                            </Box>
                          </Flex>
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
      )}
    </>
  );
};

export default ActivityDetails;
