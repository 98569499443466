import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Text,
  Flex,
  Center,
  SimpleGrid,
  Container,
} from '@chakra-ui/react';
import { getSessionPrediction } from '../../services/Common';
import CustomDoughnutChart from '../../components/Chart/CustomDoughnutChart';
import CustomPieChart from '../../components/Chart/CustomPieChart';
import Lottie from 'lottie-react';
import spinner from '../../components/Loader/spinner.json';

const ActivitySessionResults = () => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let { sessionId } = useParams();

  const fetchPrediction = async id => {
    setIsLoading(true);
    try {
      const res = await getSessionPrediction(id);

      if (res.status === 200) {
        setResults(res.data.results);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPrediction(sessionId);
  }, []);

  return (
    <Box>
      <Center>
        <Text fontSize="xl">Session Analysis</Text>
      </Center>

      {isLoading && (
        <Box
          position="fixed"
          top="50%"
          left="55%"
          transform="translate(-50%, -50%)"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Container maxW="6xl">
            <Center>
              <Box>
                <Lottie animationData={spinner} loop={true} />
              </Box>
            </Center>
          </Container>
        </Box>
      )}

      {!isLoading && (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mx={4}>
          <Box p={4}>
            <CustomDoughnutChart data={results} />
          </Box>
          <Box p={4}>
            <CustomPieChart data={results} />
          </Box>
        </SimpleGrid>
      )}
    </Box>
  );
};

export default ActivitySessionResults;
