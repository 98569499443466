import {
  Box,
  Stat,
  Center,
  WrapItem,
  Wrap,
  Text,
  Divider,
  Flex,
  StatLabel,
  StatNumber,
  Spacer,
  Select,
  useStyleConfig,
  Collapse,
  useDisclosure,
  Skeleton,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { FiActivity } from 'react-icons/fi';
import BackgroundNoiseChecker from '../../components/BackgroundNoiseChecker';
import { getActivityList, getActivitySession } from '../../services/Common';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const ThemeMenu = ({ item, open }) => {
  const handleNavigateUrl = url => {
    window.location.href = url;
  };

  return (
    <Box mt={4}>
      <Collapse
        in={open}
        transition={{
          exit: { delay: 0.2 },
          enter: { duration: 0.5 },
        }}
      >
        {item.theme.map((th, index) => (
          <Box
            key={index}
            p={2}
            color="white"
            mt={2}
            bg="#232340"
            border={'1px solid '}
            borderColor={'#1EE66E'}
            rounded="sm"
            shadow="md"
            cursor="pointer"
            onClick={e =>
              handleNavigateUrl(`${item.endpoint}/${item._id}?option=${th._id}`)
            }
          >
            <Text>{th.theme}</Text>
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};

export default ThemeMenu;
