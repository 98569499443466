import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Center, IconButton, Spacer } from '@chakra-ui/react';
import {
  FaLaptop,
  FaPlay,
  FaStop,
  FaPause,
  FaStepBackward,
  FaStepForward,
  FaUser,
  FaRecordVinyl,
  FaUndo,
} from 'react-icons/fa';
import { MdRecordVoiceOver } from 'react-icons/md';
import styled, { keyframes } from 'styled-components';
import BackgroundNoiseChecker from '../BackgroundNoiseChecker';
import AudioPlayer from '../AudioPlayer';
import AnimatedBox from './AnimatedBox';

const SystemResponse = ({
  currentIndex,
  currentDialogue,
  handleNextDialogue,
  handlePrevDialogue,
  length,
}) => {
  const [animationDirection, setAnimationDirection] = useState('up');
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [synthInstance, setSynthInstance] = useState(null);
  const [isPause, setIsPause] = useState(false);

  useEffect(() => {
    const synth = window.speechSynthesis;
    setSynthInstance(synth);

    if (synth && !synth.getVoices().length) {
      const voicesChanged = () => {
        setSynthInstance(synth);
        synth.removeEventListener('voiceschanged', voicesChanged);
      };
      synth.addEventListener('voiceschanged', voicesChanged);
    }
  }, []);

  const nextDialogue = () => {
    handleClickStop();
    setAnimationDirection('up');
    handleNextDialogue();
  };

  const prevDialogue = () => {
    handleClickStop();
    setAnimationDirection('down');
    handlePrevDialogue();
  };

  const handleSystemSpeech = text => {
    setIsSpeaking(true);
    const voices = synthInstance.getVoices();
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = voices.find(
      voice => voice.name === 'Google US English' || voice.lang === 'en-US'
    );
    utterance.pitch = 1;
    utterance.rate = 0.9;
    utterance.onend = () => {
      setIsSpeaking(false);
    };

    synthInstance.speak(utterance);
  };

  const handleClickPauseResume = () => {
    if (synthInstance) {
      if (isSpeaking) {
        setIsPause(true);
        synthInstance.pause();
        setIsSpeaking(false);
      } else {
        synthInstance.resume();
        setIsSpeaking(true);
      }
    }
  };

  const handleClickStop = () => {
    if (synthInstance) {
      synthInstance.cancel();
      setIsSpeaking(false);
      setIsPause(false);
    }
  };

  return (
    <Box overflow="hidden">
      <AnimatedBox direction={animationDirection}>
        {currentDialogue && (
          <Center>
            <Flex m={6}>
              {!isSpeaking && !isPause && (
                <IconButton
                  m={4}
                  p={2}
                  variant="ghost"
                  icon={<FaPlay color="#1EE66E" fontSize="24px" />}
                  onClick={() => handleSystemSpeech(currentDialogue.text)}
                />
              )}

              {(isSpeaking || isPause) && (
                <IconButton
                  m={4}
                  p={2}
                  variant="ghost"
                  icon={<FaPause color="#1EE66E" fontSize="24px" />}
                  onClick={handleClickPauseResume}
                />
              )}

              {(isSpeaking || isPause) && (
                <IconButton
                  m={4}
                  p={2}
                  variant="ghost"
                  icon={<FaStop color="#f56565" fontSize="24px" />}
                  onClick={handleClickStop}
                />
              )}
            </Flex>
          </Center>
        )}
        <Flex>
          <IconButton
            mr={4}
            isRound
            variant="solid"
            bg="#1EE66E"
            icon={<FaLaptop color="black" fontSize="22px" />}
            _hover={{ bg: '#1FB159' }}
          />
          <Box p={8} bg="#232340" borderRadius={10}>
            <Text fontSize="xl" pl={4} pr={4}>
              {currentDialogue.text}
            </Text>
          </Box>
        </Flex>
        <Center>
          <Flex m={6}>
            <IconButton
              isDisabled={currentIndex === 0}
              m={4}
              isRound
              variant="solid"
              bg="#1EE66E"
              icon={<FaStepBackward color="black" />}
              _hover={{ bg: '#1FB159' }}
              onClick={prevDialogue}
            />
            <IconButton
              isDisabled={currentIndex >= length - 1}
              m={4}
              isRound
              variant="solid"
              bg="#1EE66E"
              icon={<FaStepForward color="black" />}
              _hover={{ bg: '#1FB159' }}
              onClick={nextDialogue}
            />
          </Flex>
        </Center>
      </AnimatedBox>
    </Box>
  );
};

export default SystemResponse;
