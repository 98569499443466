import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  SkeletonText,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { listUsers } from '../../services/Admin';

const Users = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  const fetchUsers = async () => {
    try {
      const res = await listUsers();
      if (res.status === 200) {
        setList(res.data.users);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box mx={'auto'} p={4} px={{ base: 2, sm: 12, md: 17 }}>
      <Breadcrumb pb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="#">Users</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      {list.length === 0 && (
        <SkeletonText
          padding={4}
          mt="4"
          noOfLines={4}
          spacing="4"
          skeletonHeight="2"
        />
      )}

      {list.length !== 0 && (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Username</Th>
                <Th>Email</Th>
              </Tr>
            </Thead>
            <Tbody>
              {list.map((item, index) => (
                <Tr
                  key={index}
                  cursor="pointer"
                  onClick={() => navigate(`/admin/users/${item._id}`)}
                >
                  <Td>{index + 1}</Td>
                  <Td>{item.username}</Td>
                  <Td>
                    {item.email}
                    {item.isAdmin && <Badge colorScheme="green">Admin</Badge>}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Users;
