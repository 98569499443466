import React, { useState, useEffect } from 'react';
import { Box, Text, Spacer, Flex } from '@chakra-ui/react';

import Lottie from 'lottie-react';
import animationData from './music.json';

const BackgroundNoiseChecker = ({ backgroundNoise, text, isAnimation }) => {
  const [isMicrophoneAvailable, setIsMicrophoneAvailable] = useState(false);
  const [backgroundNoiseLevel, setBackgroundNoiseLevel] = useState(0);
  const [isBackgroundNoiseHigh, setIsBackgroundNoiseHigh] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioContext, setAudioContext] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [dataArray, setDataArray] = useState(null);

  useEffect(() => {
    const initMicrophone = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setMediaStream(stream);
        const context = new (window.AudioContext ||
          window.webkitAudioContext)();
        setAudioContext(context);
        const analyserNode = context.createAnalyser();
        const source = context.createMediaStreamSource(stream);
        source.connect(analyserNode);
        analyserNode.fftSize = 256;
        const bufferLength = analyserNode.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        setDataArray(dataArray);
        setAnalyser(analyserNode);
        setIsMicrophoneAvailable(true);
      } catch (error) {
        console.error('Microphone access denied:', error);
        setIsMicrophoneAvailable(false);
      }
    };

    initMicrophone();

    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
      }
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isRecording && analyser && dataArray) {
      const checkNoiseLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const average =
          dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;

        const sumAmplitude = dataArray.reduce((sum, value) => sum + value, 0);
        const averageAmplitude = sumAmplitude / dataArray.length;

        const averageDecibels = 20 * Math.log10(averageAmplitude / 255);
        const positiveAverageDecibels = Math.abs(averageDecibels);

        // console.log(positiveAverageDecibels);
        setBackgroundNoiseLevel(positiveAverageDecibels);
        setIsBackgroundNoiseHigh(positiveAverageDecibels > 40); // Threshold for background noise level
        backgroundNoise(positiveAverageDecibels > 40);
      };

      const interval = setInterval(checkNoiseLevel, 50);
      return () => clearInterval(interval);
    }
  }, [isRecording, analyser, dataArray]);

  const handleRecording = () => {
    if (isRecording) {
      setIsRecording(false);
    } else {
      setIsRecording(true);
    }
  };

  useEffect(() => {
    handleRecording();
  }, []);

  return (
    <Box pl={4} py={8}>
      {text && (
        <Text fontSize="lg" as="b">
          {text}
        </Text>
      )}

      {isAnimation && <Lottie animationData={animationData} loop={true} />}

      <Flex>
        <Box></Box>
        <Spacer />
        <Box>
          <Text
            fontSize="xs"
            color={isBackgroundNoiseHigh ? '#FB493F' : '#1EE66E'}
          >
            {isBackgroundNoiseHigh
              ? 'Too much background noise'
              : 'Audio is clear'}{' '}
            {backgroundNoiseLevel.toFixed(2)} dB
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default BackgroundNoiseChecker;
