// uploadAudio.js
import s3 from './aws-config';

const uploadAudioFile = (file, fileName) => {
  const params = {
    Bucket: 'parelcodev',
    Key: `Vst/audioFiles/${fileName}`,
    Body: file,
    ContentType: 'audio/mp3',
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        reject(err);
      }
      resolve(data.Location); // Return the S3 URL
    });
  });
};

export default uploadAudioFile;
