import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Center, IconButton, Spacer } from '@chakra-ui/react';

import SystemResponse from './SystemResponse';
import UserResponse from './UserResponse';

const ConversationCard = ({
  setCurrentIndex,
  currentIndex,
  currentDialogue,
  handleNextDialogue,
  handlePrevDialogue,
  length,
  isUser,
  saveAudio,
}) => {
  const [audioList, setAudioList] = useState([]);

  return (
    <Box overflow="hidden">
      {!isUser && (
        <SystemResponse
          currentIndex={currentIndex}
          currentDialogue={currentDialogue}
          handleNextDialogue={handleNextDialogue}
          handlePrevDialogue={handlePrevDialogue}
          length={length}
        />
      )}

      {isUser && (
        <UserResponse
          currentIndex={currentIndex}
          currentDialogue={currentDialogue}
          handleNextDialogue={handleNextDialogue}
          handlePrevDialogue={handlePrevDialogue}
          length={length}
          saveAudio={saveAudio}
        />
      )}
    </Box>
  );
};

export default ConversationCard;
